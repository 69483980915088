export const Card = ({
  className,
  title,
  icon,
  iconRounded = false,
  onClick = () => null,
  isAvailable = true,
  description = '',
}) => {
  return (
    <div
      className={`explore__card ${
        isAvailable ? '' : 'explore__card--unavailable'
      } ${className}`}
      onClick={onClick}
    >
      {iconRounded ? (
        <div className="explore__card__icon">
          <img src={icon} alt={` icon`} />
        </div>
      ) : (
        <img src={icon} alt={`${title} icon`} />
      )}
      <p className="explore__card__text">
        <strong>{title}</strong>
      </p>
      {!!description ? (
        <>
          <p className="explore__card__description">{description}</p>
        </>
      ) : null}
    </div>
  )
}
